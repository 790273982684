import React, { useState } from 'react'
import { t } from '../../../../../src/common/i18n'
import { navigate } from 'gatsby'
import Step, {
  StepDetails,
} from '../../../../../src/common/components/FunnelSelector/SelectorSteps'
import SelectorModal from '../../../../../src/common/components/FunnelSelector/Entrada/SelectorModal'
import CestaLastRow from '../../../../../src/common/components/cesta/cestaItem/cestaLastRow/CestaLastRow'

type Props = {
  onComprarClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
export const BuscarNeumaticosCesta = ({ onComprarClick }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const onSubmitCoche = () => {
    navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
  }

  const openSelectorModal = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    setShowModal(true)
    return null
  }
  return (
    <>
      <SelectorModal
        title={t('tu_solicitud.elige_neumatico')}
        closeModal={() => {
          setShowModal(false)
        }}
        showModal={showModal}
        onSubmit={onSubmitCoche}
      />
      <CestaLastRow
        onContinuarClick={onComprarClick}
        openSelectorModal={openSelectorModal}
      />
    </>
  )
}
